body{margin:0;font-family:-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}
code{font-family:source-code-pro,Menlo,Monaco,Consolas,'Courier New',monospace}
label{font-weight:500}
select.form-control:not([size]):not([multiple]){height:calc(2.25rem + 2px)}
.fa-info-circle{font-size:20px!important}
.width-50{width:50%!important;height:calc(1.5em + .5rem + 2px)!important}
.checklist-completing{text-decoration:line-through}
.login-img{min-height:720px;background-position:center;background-repeat:no-repeat;background-size:cover;position:relative}
.rdw-image-modal{left:-150px!important}
.rdw-editor-wrapper{background:#fff;margin-top:5px}
.main-log-img{text-align:center;margin-bottom:45px}
.main-login{background-color:#f3fcfc}
.main-login h3{text-align:center}
.user-top-outer{text-align:right;margin-bottom:5px}
.instance-version{position:absolute;bottom:0;font-size:14px}
.fab-export-btn{margin-right:25px;margin-top:10px}
.goto-link-a{cursor:pointer}
.type-checkbox .col-md-4{font-size:12.5px;margin-bottom:4px}
.fab-export-btn button{padding:6px 40px;font-size:18px;height:inherit!important}
.fab-export-btn-top button{padding:2px 25px}
.custom-trash{font-size:24px!important}
.float-right{float:right!important;margin-top:4px}
.input-group-append{margin-left:-1px}
.input-group-append,.input-group-prepend{display:flex}
.build-details{font-size:14px!important}
.append-btn{border-top-left-radius:0!important;border-bottom-left-radius:0!important}
.upload-box button{border-radius:4px!important}
.login-img{background-position:50%;background-repeat:no-repeat;background-size:cover;display:flex;flex:3 1;flex-direction:column;height:100vh;justify-content:center}
.fadeInLeft.is-open,.fadeInRight.is-closed{background:url('/public/layout_arrows.png') no-repeat 0 0!important}
.fadeInLeft.is-closed,.fadeInRight.is-open{background:url('/public/layout_arrows.png') no-repeat 0 -16px!important}
.sign-up{text-align:center}
.sign-up span{color:#00f;cursor:pointer}
.error{border-color:red!important}
.status-list-workflow{padding:10px;width:90%!important}
.input-section-h{width:65%;margin:0 auto}
.pd-0{padding:0!important}
.cat-lable{vertical-align:-webkit-baseline-middle;margin-bottom:inherit!important}
.builder-filter{width:50%!important}
.task-list-label{font-size:18px;font-weight:500;text-align:center;margin-top:0}
.tasks-table-div{clear:both;margin-top:15px}
.tasks-table-div-2{clear:both;margin-top:15px}
.task-div{border:1px solid #ccc;padding:3px 10px;width:95%;margin-bottom:5px;position:relative}
.tasks-table-div input{vertical-align:middle;margin-right:15px}
.tasks-table-div-2 input{vertical-align:middle;margin-right:15px}
.task-input-2{width:70%;display:inline-block}
.workflow-div{border:2px solid #ccc;padding:15px;margin:10px 0;height:382px}
.workflow-select-list{max-height:350px;overflow-y:scroll}
.tasks-table-div{max-height:460px;overflow-y:scroll;padding:5px}
.tasks-table-div-3{max-height:600px;overflow-y:scroll;padding:5px}
.tasks-table-div-2{max-height:385px;overflow-y:scroll;padding:5px}
.status-inner{position:relative;width:100%;margin:0 auto}
.status-inner .field-div{margin:2px auto;width:100%}
.light-yellow{background-color:#f5f5c5}
.light-green{background-color:#a4f8a4}
.light-blue{background-color:#a1e8f9}
.delete-checkbox{margin-right:5px;vertical-align:middle}
.workflow-save{margin-top:0;width:100%;margin-right:30px}
.save-lable{margin-top:4px;margin-right:5px}
.import-btn{margin-top:0;border:none;font-size:24px;float:right;margin-left:5px}
.goto-radio{display:flex}
.btn-goto-radio{margin-right:20px}
.btn-goto-radio input{width:16px;height:16px}
.sharing-code-btn{border:none;font-size:22px;padding:0!important;margin:-5px!important}
.copy-code-btn{padding:0!important}
.copy-code-btn i{font-size:18px;padding:5px;margin-top:5px}
.workflow-manager{padding:10px}
.filter-checkbox{padding:0 10px 0 2px}
.start-wc .row{padding:5px 0}
.start-wc .col-4{font-size:14px}
.wfilters{width:60%;margin:0 auto 20px;position:relative}
.wc-filter{margin-bottom:5px}
.cat-manage{position:absolute;right:0;top:20px}
.workflow-list h4{text-align:center;margin-top:10px}
.wfilters label{width:80px}
.wfilters select{display:inline;margin-right:15px}
.w-name{text-align:left!important}
.builder-filter{width:50%!important}
.cat-manage{position:absolute;right:0;top:20px}
.cat-manage button{padding:5px 8px;font-size:14px}
.workflow-list-table.table td,.workflow-list-table.table th{padding:3px 10px!important}
.workflow-list-table{padding:0 10px!important;height:20px;font-size:14px;text-align:center}
.workflow-list-table button{padding:0 10px!important;height:20px;font-size:14px}
.left-check-box{text-align:left;padding:2px;margin-right:12px}
.tpm-filter{display:flex}
.tpm-filter-2{margin-bottom:10px}
.btn-edit-workflow{padding:2px!important}
.task-list-workflow,.wdata-scheduled{padding:2px;width:75%;margin:auto 15px}
.select-workflow-div{display:flex}
.workflow-tasks-div{max-height:430px;overflow-y:scroll;clear:both;margin-top:15px;font-size:14px}
.wname-day-count{text-align:center;margin-top:10px}
.workflow-tasks-div .task-div{border:1px solid #ccc;padding:3px 10px;width:100%;margin-bottom:5px;position:relative}
.task-input-5{width:26%;display:inline-block;overflow:hidden;height:22px}
.workflow-tasks-div .input-role{font-size:12px;width:14%;display:inline-block}
.workflow-tasks-div .input-person{font-size:14px;width:12%;display:inline-block}
.input-days{display:inline-block;width:60px}
.task-icon{margin-left:3px}
.task-edit{margin-top:5px}
.right-side-btn{text-align:right!important}
.code-dialog{padding:0!important;margin-right:4px}
.workflow-tasks-div .input-person,.workflow-tasks-div .input-role,.workflow-tasks-div .input-status{border:1px solid #ccc;margin:0 2px;padding:0 5px;background-color:#fff}
.hamburger{background:0 0;border:none;margin-left:2px;position:fixed;top:68px;z-index:999}
.fadeInRight{right:20px;-webkit-transition:all .5s ease;transition:all .5s ease}
.fadeInLeft,.fadeInRight{display:inline-block;width:16px;height:16px}
#wrapper2.toggled-3 #sidebar-wrapper-right{width:440px}
#sidebar-wrapper-right{background:#f0f0f0;height:100%;right:220px;margin-right:-220px;overflow-x:hidden;overflow-y:auto;-webkit-transition:all .5s ease;transition:all .5s ease;width:0;z-index:1000;border:2px solid #ccc}
.nav-content-right{padding:10px 30px;width:430px}
.page-title,.panel-btn{text-align:center}
.workflow-item-fields{margin:10px 0 120px}
.custom-navbar-2,.custom-navbar-4{position:fixed;right:0;z-index:1030;top:66px;border-width:0 0 1px}
.btn-update-status{float:left;margin-top:10px}
.row-input label{margin-bottom:0!important}
.do-not-share input{display:inline-block;width:auto;vertical-align:bottom;margin-left:24px}
.column-input input,.row-input input{display:inline-block;margin-bottom:5px}
#wrapper2.toggled-3 .fadeInRight{right:454px;-webkit-transition:all .5s ease;transition:all .5s ease}
.workflow-option{font-size:12px}
.input-option{width:92px;display:inline-block}
.nav-content-right{padding:10px 30px;width:430px}
.workflow-item-fields{margin:10px 0 120px}
.text-box,.workflow-checkbox{margin-top:10px}
.text-center{text-align:center!important}
.workflow-first-task{width:96%;margin:0 auto;font-size:13px;padding:10px}
.show-all-note{display:flex;gap:10px;align-items:baseline}
.workflow-filters{margin-bottom:15px;display:inline-flex;overflow-x:scroll}
.guides-filters{margin-bottom:15px;display:flex;gap:5px;align-items:center;margin-left: 20px;}
.goto-filters{display:flex;margin-bottom:5px;position:relative}
.wp-filter{margin-top:10px}
.startenddate{width:220px;margin-right:5px}
.wp-filter-reset button{margin-top:25px}
.wdata-table-div h4{text-align:center;display:inline-block;margin:0 auto}
.show-all-note{float:right;margin-right:10px}
.wp-filter select{font-size:12px!important;padding:5px}

.duestartend{display:flex;align-items:center;gap:1px;width:240px;margin-top:10px}
.duestartend .MuiInputBase-root{width:205px!important;font-size:12px}
.duestartend input{font-size:14px!important;height:40px}


.feedatefilter{display:flex;align-items:center;gap:1px;width:240px;margin-top:10px}
.feedatefilter .MuiInputBase-root{width:117px!important;font-size:12px}
.feedatefilter input {font-size: 12px !important; height: 5px;}

.feedatefilter2{display:flex;align-items:center;gap:1px;width:280px;margin-top:10px}
.feedatefilter2 .MuiInputBase-root{width:135px!important;font-size:12px}
.feedatefilter2 input {font-size: 12px !important; height: 5px;}
.feedatefilter2 label {font-size: 12px !important;}


.startenddate label{margin:0}
.note-row{display:none;background-color:#ffeac4}
.show-all-note .showAllSwitch{margin-right:5px}
.showAllSwitch{position:relative;display:inline-block;width:40px;height:22px}
.show-all-note{float:right;margin-right:10px}
#showAllNotes,#showAtRiskStatus{visibility:hidden}
.instance-manager #showOutputText,.showAllSwitch input{visibility:hidden}
.slider.round{border-radius:34px}
.slider{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#ccc;-webkit-transition:.4s;transition:.4s}
.slider.round:before{border-radius:50%}
.showAllSlide:before{position:absolute;content:"";left:2px;background-color:#fff;-webkit-transition:.4s;transition:.4s}
.embedslider:before,.showAllSlide:before{height:19px;width:23px;bottom:2px}
input:checked+.slider{background-color:#2196f3}
input:checked+.showAllSlide:before{-webkit-transform:translateX(11px);transform:translateX(11px)}
.slider.round:before{border-radius:50%}
.showAllSlide:before{position:absolute;content:"";left:2px;background-color:#fff;-webkit-transition:.4s;transition:.4s}
.row-red{background-color:#f3baba}
.row-green{background-color:#a3f5a3}
.row-green span{float:right;font-weight:700;color:red}
.row-yellow{background-color:#f7f7b2}
.task-note,.task-wip-note{cursor:pointer}
.task-icon{margin-left:5px}
.modal-dialog{max-width:650px!important}
.input-wip-note{height:40px!important}
.btn-wip-note{margin-left:2px}
.add-wip-note2,.workflow-filters{display:flex;gap:5px;align-items:center}
.wip-Note-box{padding:10px;background-color:#fff;max-height:165px;overflow-y:scroll;font-size:14px}
.job-header-section{font-size:16px;margin-bottom:10px;padding:5px;background-color:#f2f2f2}
.back-to-view{float:right;text-decoration:underline;color:#0545cf}
.simple-to-view{float:right;text-decoration:underline;color:#0545cf;cursor:pointer;padding:0 5px}
.back-to-view,.view-workflow-details{cursor:pointer}
.job-header-section span{margin-right:20px}
#wrapper3.toggled-3 #sidebar-wrapper-right{width:400px}
#wrapper3.toggled-3 .fadeInRight{right:405px;-webkit-transition:all .5s ease;transition:all .5s ease}
.super-view{margin-top:10px}
.css-1pcon9w-MuiFormControl-root{margin:0}
.workflow-filters>div>div{margin:0}
.guides-filters>div>div{margin:0}
.super-view img{cursor:pointer}
.workflow-super-view{width:88%;margin:0 auto;font-size:13px;padding:10px}
.workflow-super-table.table td,.workflow-super-table.table th{padding:3px 10px}
.task-header-th{width:42px;max-height:155px;padding:5px 10px!important}
.task-header-span{-webkit-text-orientation:mixed;text-orientation:mixed;-webkit-writing-mode:vertical-lr;-ms-writing-mode:tb-lr;writing-mode:vertical-lr;font-size:12px}
.back-to-view,.view-workflow-details{cursor:pointer}
.new-super-view{width:60px;text-align:center}
.scheduled-input .row-input input,.scheduled-input .row-input select{width:340px!important;padding:8px}
.scheduled-input .row-input{max-width:340px}
.user-list .table td,.user-list .table th{padding:3px 10px!important}
.dropdown-check-list{display:inline-block;position:relative;margin-left:10px}
.dropdown-check-list .anchor{position:relative;cursor:pointer;display:inline-block;padding:5px 50px 5px 10px;border:1px solid #ccc;width:180px;border-radius:5px}
.dropdown-check-list .anchor:after{position:absolute;content:"";border-left:2px solid #000;border-top:2px solid #000;padding:5px;right:10px;top:20%;-moz-transform:rotate(-135deg);-ms-transform:rotate(-135deg);-o-transform:rotate(-135deg);-webkit-transform:rotate(-135deg);transform:rotate(-135deg)}
.dropdown-check-list .anchor:active:after{right:8px;top:21%}
.dropdown-check-list ul.items{padding:2px;display:none;margin:0;border:1px solid #ccc;border-top:none}
.dropdown-check-list ul.items li{list-style:none;text-align:left;padding:0 8px}
.dropdown-check-list.visible .anchor{color:#0094ff;width:180px}
.dropdown-check-list.visible .items{display:block;position:absolute;z-index:999;width:180px;background-color:#fff}
.li-span{padding:1px 5px;vertical-align:text-bottom}
.user-list .table{text-align:center}
.user-list h4{text-align:center;margin-top:10px}
.user-list{width:80%;margin:0 auto}
.role-option{font-size:12px;width:165px}
.add-user{float:right;margin-bottom:5px}
.user-table .fa{font-size:18px}
.btn-add-user{text-decoration:underline;color:#3838ff!important}
.role-option-2{font-size:12px;width:165px!important;clear:both;display:block}
.tracker-table.table td.note-row{padding:0!important;border:none}
.tracker-table.table td,.tracker-table.table th{padding:2px 8px!important}
.fa-trash{color:#f60404;cursor:pointer}
.task-list-nas .fa-trash{color:#fff;cursor:pointer}
.task-list-nas button{padding:2px 20px}
.checklist-add{margin-top:10px;margin-bottom:10px}
.label-list{display:block}
.list-sec{margin-top:5px;margin-bottom:5px}
.add-checklist{position:relative}
.delete-checklist{position:absolute;top:-25px;left:100px}
.icon-checklist:before{content:"\e91a"}
.task-list-option{margin-bottom:5px}
.task-list-option input[type=checkbox]{cursor:pointer;height:14px;margin:0;width:14px}
.checklist-completed{text-decoration:line-through;font-size:14px}
.custom-progress-bar{width:100%;margin-bottom:10px;position:relative}
.custom-progress-bar progress{width:90%;margin-left:40px;height:20px}
.custom-progress-bar span{position:absolute;top:0}
.list-header{margin-bottom:20px;font-size:16px}
.modal-header-costom{height:15px}
.modal-header-costom button{margin-top:-5px}
.list-header input{height:14px;width:14px;margin-right:2px}
.custom-btn{padding:0 5px!important}
.hide-row{display:none!important}
.delete-checklist-item{margin-left:10px}
.change-status-com{margin-left:20px}
#qr-code-img{margin-bottom:10px}
.rq-code-canvas{width:150px;margin:auto}
.qr-code-btn{padding:0!important}
.qr-code-button{margin-top:10px}
.qr-code-button button{font-size:20px;padding:2px 8px}
.goto-page{width:50%;margin:auto}
.goto-page h6{text-align:center;margin-top:20px}
.goto-page input{width:55%;margin:auto;margin-top:20px}
.goto-input{text-align:center}
.top-right-login{display:none}
.check-disable{vertical-align:middle}
.has-checklist{display:block!important}
.task-details-tb{margin-bottom:0!important}
.task-details-tb th{padding:2px 10px!important;width:25%}
.task-details-tb td{padding:2px 10px!important}
.css-1howxi1-MuiTableCell-root{font-weight:600!important}
.MuiTableBody-root .MuiSelect-select{padding:10px!important}
.MuiInputBase-inputSizeSmall,.workflow-filters .MuiInputLabel-sizeSmall,.guides-filters .MuiInputLabel-sizeSmall{font-size:14px!important}

.workflow-first-task .css-34nofg-MuiTableRow-root:last-child td,.workflow-first-task .css-34nofg-MuiTableRow-root:last-child th{border-bottom:1px solid #e0e0e0!important}
.status-list{padding:10px}
.status-list h4,.status-list-workflow h4,.task-list-workflow h4{text-align:center}
.status-list button{height:38px}
.status-list .col-md-3,.status-list .col-md-6{padding:2px}
.hide-item{display:none!important}
.all-show{display:table-cell!important}
.task-name-cl{min-width:250px!important;width:400px!important}
.task-name-c2{min-width:250px!important;max-width:300px!important}
.t-patient,.t-view-d{text-align:center!important}
.tag-section{margin:5px 0}
.btn-insert-video{padding:2px 5px}
.v-tag{padding:5px;background-color:#efefef;display:inline-block;margin-right:10px}
.v-list{font-size:14px}
.video-section h6{margin-bottom:10px}
.video-section-table{max-height:265px;overflow-y:scroll}
.video-section table td{padding:5px 10px}
.btn-embed{padding:4px 8px!important}
.video-preview{background:rgb(0 0 0 / 42%)}
.modal-video-pre{max-width:435px!important}
.video-list-div{min-height:400px}
.btn-insert-video{padding:0 5px!important;margin-left:5px}
.in-pro-table th{background-color:#e9e9e9;color:#000}
.tp-filter{display:flex;gap:5px}
.tp-filter span{font-size:12px}
.tp-filter span input{vertical-align:middle}
.link-box{position:absolute;right:30px;margin-top:3px;margin-right:-20px}
.link-box a{font-size:12px;margin-left:8px;text-decoration:underline}
.task-icon button{margin:0}
.workflow-manager i{font-size:18px}
.btn-edit-user{margin-top:-5px}
#wrapper.toggled{padding-left:300px}
#wrapper,#wrapper.toggled{padding-top:0!important}
#wrapper{padding-left:0;-webkit-transition:all .5s ease;transition:all .5s ease}
.toggled aside{-webkit-transform:translateX(0);transform:translateX(0);background:#f0f0f0}
.toggled aside .toggle .burger span,.toggled aside .toggle .burger:after,.toggled aside .toggle .burger:before{background:#000}
.burger{width:20px;height:24px;cursor:pointer;position:relative;z-index:99;float:right}
.burger span,.burger:after,.burger:before{width:100%;height:2px;display:block;background:#000;border-radius:2px;position:absolute;opacity:1}
.burger:after,.burger:before{-webkit-transition:top .35s cubic-bezier(.23, 1, .32, 1),opacity .35s cubic-bezier(.23, 1, .32, 1),background-color 1.15s cubic-bezier(.86, 0, .07, 1),-webkit-transform .35s cubic-bezier(.23, 1, .32, 1);transition:top .35s cubic-bezier(.23, 1, .32, 1),opacity .35s cubic-bezier(.23, 1, .32, 1),background-color 1.15s cubic-bezier(.86, 0, .07, 1),-webkit-transform .35s cubic-bezier(.23, 1, .32, 1);-o-transition:top .35s cubic-bezier(.23, 1, .32, 1),transform .35s cubic-bezier(.23, 1, .32, 1),opacity .35s cubic-bezier(.23, 1, .32, 1),background-color 1.15s cubic-bezier(.86, 0, .07, 1);transition:top .35s cubic-bezier(.23, 1, .32, 1),transform .35s cubic-bezier(.23, 1, .32, 1),opacity .35s cubic-bezier(.23, 1, .32, 1),background-color 1.15s cubic-bezier(.86, 0, .07, 1);transition:top .35s cubic-bezier(.23, 1, .32, 1),transform .35s cubic-bezier(.23, 1, .32, 1),opacity .35s cubic-bezier(.23, 1, .32, 1),background-color 1.15s cubic-bezier(.86, 0, .07, 1),-webkit-transform .35s cubic-bezier(.23, 1, .32, 1);-webkit-transition:top .35s cubic-bezier(.23, 1, .32, 1),-webkit-transform .35s cubic-bezier(.23, 1, .32, 1),opacity .35s cubic-bezier(.23, 1, .32, 1),background-color 1.15s cubic-bezier(.86, 0, .07, 1);content:""}
.burger span{top:15px}
.burger:before{top:7px}
.burger:after{top:23px}
.burger.active span{opacity:0}
.burger.active:after,.burger.active:before{top:40%}
.burger.active:before{-webkit-transform:rotate(45deg);-moz-transform:rotate(45deg)}
.burger.active:after{-webkit-transform:rotate(-45deg);-moz-transform:rotate(-45deg)}
.burger:focus{outline:0}
aside{width:300px;left:0;z-index:1001;position:fixed;-webkit-transform:translateX(-100%);transform:translateX(-100%);background-color:#fff;-webkit-transition:-webkit-transform 1s cubic-bezier(.23,1,.32,1);transition:-webkit-transform 1s cubic-bezier(.23,1,.32,1);transition:transform 1s cubic-bezier(.23,1,.32,1);transition:transform 1s cubic-bezier(.23,1,.32,1),-webkit-transform 1s cubic-bezier(.23,1,.32,1)}
aside,main{height:100vh;min-height:580px}
.burger{width:20px;height:24px;cursor:pointer;position:relative;z-index:99;float:right}
aside .side-inner{height:100vh;overflow-y:scroll;-webkit-overflow-scrolling:touch;background-size:cover;background-position:50%;background-repeat:no-repeat}
.align-items-end{align-items:flex-end!important}
.nav-content{padding:10px 20px;width:292px}
.workflow-update-person{width:150px!important;height:32px!important}
.output-pdf{float:right;margin-top:-40px}
.hidden-table{display:none}
#wrapper.toggled #page-content-wrapper{margin-right:-300px}
#page-content-wrapper{padding-top:4px;width:90%}
aside .toggle{padding-left:6px;padding-top:5px;position:absolute;right:0;-webkit-transform:translateX(100%);transform:translateX(100%);z-index:999999}
.eye-giud{display:inline-flex}
.gt-description{min-width:300px}
.gt-type{min-width:150px}
.gt-name{min-width:250px}
.gt-cat{min-width:150px}
.gt-td-width{min-width:120px}
.cat-heading{font-size:18px;font-weight:500;padding:8px}
.panel-btn{margin-top:20px}
.left-link-box{margin-top:50px}
.left-link-box-2{margin-top:25px}
.left-link-top-box{margin-bottom:25px}
.left-link-box .btn-header-link{display:block;color:#222;padding:6px;background-color:#fff;border:1px solid #ccc;text-align:center;margin-top:10px;width:100%}

.left-link-box-new{margin-top:15px}
.left-link-box-new .btn-header-link{display:block;color:#222;padding:6px;background-color:#fff;border:1px solid #ccc;text-align:center;margin-top:10px;width:100%}

.left-link-top-box .btn-top-header-link{display:block;color:#222;padding:6px;background-color:#fff;border:1px solid #ccc;text-align:center;margin-top:10px}
.change-task-cat{margin-top:15px}
.disablelist{visibility:hidden;height:0;margin:0!important;padding:0!important;border:0!important}
.add-link-value{position:absolute;right:0;margin-top:28px}
.color-label{display:inline-block;width:100px;text-align:center;border-radius:4px;padding:10px 5px;cursor:pointer}
.color-label:hover{text-decoration:none}
.color-id{width:60px}
.color-name{width:250px}
.color-action{width:120px}
.color-code{padding:5px 10px}
.add-label-btn{position:absolute;bottom:38px;font-size:18px;color:#6e6e6e;padding:2px 15px;font-weight:400}
.assgin-btn{text-align:right;margin-top:10px}
.search-icon{float:right;margin-top:-26px;margin-right:10px;font-size:18px!important;color:#c5c5c5}
.custom-modal-bg{max-width:880px!important}
.company-list h4{text-align:center;margin-top:10px}
.btn-cm{float:right}
.group-list h4{text-align:center;margin-top:10px}
.api-key-manager { display: flex;justify-content: center; align-items: center;}
.key-label{padding: 10px;}
.key-input{width: 460px;}
.key-btn{padding: 5px;}
.parms-div-right{padding: 5px;height: 220px;overflow-y: scroll;}
.parms-dev{padding: 5px;height: 450px;overflow-y: scroll;}
.temp-list h4 { text-align: center; margin-top: 10px;}
.main-parm-dev{margin-left: 20px!important;}
.parms-left-checkbox{float: right;}
.parms-left-checkbox input{margin-left: 10px;width: 15px;height: 15px;}
.upload-btn{margin-top: 5px;}
.icon-complated{color: green!important;}
@media only screen and (max-width:767px){
.top-right-login{display:block}
.top-header{padding:15px!important}
.instance-version{position:inherit;margin-bottom:10px}
.top-header h4{margin-bottom:0}
.login-user-top{display:none!important}
.top-right-login .login-user-top{margin:0!important;display:block!important}
.sign-out-btn{width:100%!important}
.user-top-outer{margin-bottom:0}
.go-to-task-modal .modal-dialog{margin:0!important}
.go-to-task-modal .modal-content{border:0 solid rgba(0,0,0,.2);border-radius:0;height:100vh}
.go-to-task-modal .form-check{padding-left:0!important}
.go-to-task-modal .modal-body{flex:none}
}